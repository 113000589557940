.subMenu .item {
    padding-left: 35px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.no-selection {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}


.menu-normal {
    color: #444444;
}

.menu-normal p {
    font-weight: 400;
}

.menu-normal img {
    color: #444444;
    filter: invert(24%) sepia(2%) saturate(18%) hue-rotate(131deg) brightness(104%) contrast(94%);
}

.menu-selected {
    color: #0056D9;
    background: #F0F6FF;
}

.menu-selected img  {
    color: #0056D9;
    filter: invert(21%) sepia(90%) saturate(2242%) hue-rotate(207deg) brightness(99%) contrast(109%);

}

.sidebar {
    transition: left 0.5s;
}